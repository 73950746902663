.LicenseButton {
  padding: 0px;
  border: 0px;
}

.LicenseNotice {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  display: block;

  border-radius: 4px;
  border: 1px solid #aaaaaa;
  background: #ffffff;
  color: #222222;
  padding: 0.2em;

  .LicenseNotice__TitleBar {
    padding: 0.4em 1em;
    position: relative;
    border-radius: 4px;
    border: 1px solid #aaaaaa;
    background: #cccccc;
    color: #222222;
    font-weight: bold;
    font-size: 1.1em;

    .LicenseNotice__TitleBar__Title {
      margin: 0.1em 0;

      width: 90%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .LicenseNotice__TitleBar__Close {
      position: absolute;
      right: 0.3em;
      top: 50%;
      width: 21px;
      margin: -10px 0 0 0;
      padding: 1px;
      height: 20px;
      line-height: 16px;
    }
  }
}
