/* Sticky footer styles
-------------------------------------------------- */

html,
body {
  height: 100%;
  /* The html and body elements cannot have any padding or margin. */
}

/* Wrapper for page content to push down footer */
#wrap {
  min-height: 100%;
  height: auto !important;
  height: 100%;
  /* Negative indent footer by it's height */
  margin: 0 auto -100px;
}

/* Set the fixed height of the footer here */
#push,
#footer {
  height: 100px;
}
#footer {
  color: #999999;
  margin-bottom: 0px;
  z-index: 9999;
}
#layer-toggle {
  color: #999999;
}

/* Lastly, apply responsive CSS fixes as necessary */
@media (max-width: 767px) {
  #footer {
    display: none;
  }
}
