.LayerSelector {
  color: #337ab7;
  padding-right: 15px;

  &:hover {
    background-color: #eee;
  }

  option {
    color: rgb(35, 82, 124);
  }
}
