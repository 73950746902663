.SVGPoiMarker {
  cursor: pointer;
  pointer-events: auto;

  // Defined via the OSD SVG Overlay Plugin
  transform: var(--clampedInverseScale, scale(0));

  stroke: rgb(0, 0, 255);
  fill: #1e90ff;
}

.SVGPoiMarker__ForeignObject {
  overflow: visible;
  pointer-events: none;
  z-index: 200;

  // Defined via the OSD SVG Overlay Plugin
  transform: var(--inverseScale, scale(0));

  display: none;
  &.SVGPoiMarker__ForeignObjectActive {
    display: block;
  }

  .PoiMarker__Tooltip {
    min-width: 128px;
    width: fit-content;
    max-width: 256px;

    border: 4px solid #252525;
    border-radius: 4px;

    line-height: 1.4;
    z-index: 300;

    .PoiMarker__Tooltip__Name,
    .PoiMarker__Tooltip__Comment {
      padding: 4px;
      z-index: 400;
      background-color: #fff;
    }

    .PoiMarker__Tooltip__Name {
      font-size: 14px;
      white-space: nowrap;
    }

    .PoiMarker__Tooltip__Comment {
      font-size: 12px;
    }
  }
}
